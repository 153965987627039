
body {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: rgb(8, 22, 74);
}

/* Style for the product container */
.product-container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    background: #fff; /* White background for the content area */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.header {
    
    display: flex;
    align-items: center;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.header img {
    height: 50px; /* Adjust logo size as needed */
    margin-right: 20px;
}

.header h1 {
    font-size: 2em;
    color: #fff;
    margin: 0;
}

.msgloading {
    font-size: 2em;
    color: #fff;
    margin: 0;
}

.env-select select {
    margin-left: 20px;
    padding: 5px;
}

/* Table styling */
.product-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    border: 1px solid #ddd;
    background-color: #fff;
    color: #333;
}

.product-table thead {
    background-color: #f0f0f0;
    border-bottom: 2px solid #ddd;
}

.product-table th,
.product-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.product-table th {
    font-weight: bold;
    color: #333;
}

.product-table td {
    color: #555;
}

.product-table tbody tr:hover {
    background-color: #f9f9f9;
}

/* Check and cross symbols styling */
.check-symbol {
    color: green;
    text-align: center;
}

.cross-symbol {
    color: red;
    text-align: center;
}

/* Responsive design */
@media (max-width: 768px) {
    .product-table {
        font-size: 14px;
    }

    .product-table th,
    .product-table td {
        padding: 10px;
    }
}
